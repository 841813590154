import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@app/stores/index";
import { SearchType as AdAccountSearchType } from "@app/interface/ad_account.interface";
import { SearchType as AdAccountGroupSearchType } from "@app/interface/ad_account_group.interface";
import {
  SearchType as AdvertiserSearchType,
  Status as AdvertiserStatus,
} from "@app/interface/advertiser.interface";
import { Role } from "@app/interface/common.interface";

interface InitialState {
  [key: string]: any;
  adAccount: {
    status: any[];
    media: any[];
    currency: any[];
    category: any[];
    searchType: AdAccountSearchType;
    searchText: string;
    page: number;
    size: number;
    sort: string;
    noData: boolean;
  };
  adAccountGroup: {
    searchType: AdAccountGroupSearchType;
    searchText: string;
    page: number;
    size: number;
    sort: string;
  };
  advertiser: {
    status: AdvertiserStatus;
    searchType: AdvertiserSearchType;
    searchText: string;
    page: number;
    size: number;
    sort: string | string[];
    role?: Role;
  };
  collection: {
    page: number;
    size: number;
    sort: string;
  };
}
const name = "manage";

const initialState: InitialState = {
  // 광고계정
  adAccount: {
    status: [],
    media: [],
    currency: [],
    category: [],
    searchType: "NAME",
    searchText: "",
    page: 1,
    size: 50,
    sort: "updatedAt,desc",
    noData: false,
  },
  adAccountGroup: {
    searchType: "ACCOUNTGROUP",
    searchText: "",
    page: 1,
    size: 50,
    sort: "updatedAt,desc",
  },
  advertiser: {
    status: "ALL",
    searchType: "COMPANY",
    searchText: "",
    page: 1,
    size: 50,
    sort: "company,asc",
  },
  collection: {
    page: 1,
    size: 50,
    sort: "collectionDate,desc",
  },
};

const manageSlice = createSlice({
  name,
  initialState,
  reducers: {
    initManageParams: (state) => {
      Object.assign(state, initialState);
    },
    setManageParams: (state, action) => {
      const { target, value } = action.payload;
      // Object.assign(state[target], {...value});
      state[target] = {
        ...value,
      };
    },
  },
});

export const manageSelector = (state: RootState) => state.manage;
export const { setManageParams, initManageParams } = manageSlice.actions;
export default manageSlice.reducer;
