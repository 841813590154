import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, message, Modal } from "antd";
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import Logo from "@app/assets/images/cl_logo.svg";
import styled from "styled-components";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync, googleAsync, authSelector } from "@app/stores/auth.store";
import { LoginParams } from "@app/interface/auth.interface";
import { useNavigate } from "react-router-dom";
import { ErrorProps } from "@app/interface/common.interface";
import { ErrorText } from "@app/components/common/Text";
import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import _ from "lodash";
import { EMAIL_REGEX } from "@app/utils/variables";

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f2f5;
  height: 100vh;

  .form {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 30px 60px 0 rgb(0 0 0 / 30%);
    width: 90%;
    max-width: 450px;
  }

  .title {
    text-align: center;

    img,
    span {
      vertical-align: middle;
    }

    span {
      margin-left: 10px;
    }
  }

  .id,
  .pw,
  .input {
    margin-bottom: 10px;
  }

  .line {
    width: 100%;
    border: 1px solid #d9d9d9;
    margin-top: 20px;
  }

  .subTitle {
    margin-top: 20px;
    text-align: center;
    color: #8d8d8e;
  }

  .subTitleCopyRight {
    margin-top: 20px;
    text-align: center;
    color: #8d8d8e;
    display: inline-block;
  }

  .subTitlePrivacy {
    margin-top: 20px;
    text-align: center;
    color: #8d8d8e;
    float: right;
  }

  .subTitlePrivacy:hover {
    color: #1890ff;
  }

  .googleLogin {
    margin-top: 10px;
    background-color: #d9d9d9;
    border: #d9d9d9;
  }

  .googleLoginBtn {
    display: none !important;
  }

  .noticeError {
    color: red;
  }
`;

const LandingPage = () => {
  const { role } = useSelector(authSelector);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginParams>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState<ErrorProps>({
    result: false,
    message: "",
  });

  const [singularError, setSingularError] = useState({
    // 존재하지 않는 아이디이거나, 잘못된 비밀번호입니다. = 1,
    // 해당 계정은 비활성화된 상태로 로그인할 수 없습니다. \n와이즈버즈 담당자에게 문의해 주시기 바랍니다. = 2
    code: 0,
    message: "",
  });

  function initSingularError() {
    setSingularError({
      code: 0,
      message: "",
    });
  }

  function singularErrorModal(title: string, message: string) {
    Modal.error({
      title: title,
      content: message,
      onOk() {
        initSingularError();
      },
      okText: "닫기",
      width: 490,
    });
  }

  const onSubmit: SubmitHandler<LoginParams> = async (data) => {
    try {
      const res: any = await dispatch(loginAsync(data));
      if (res.payload.result) {
        if (role === "PARTNER") {
          navigate("/dashboard");
        } else {
          navigate("/benchmark");
        }
      } else {
        if (
          res.payload.message.includes(
            "존재하지 않는 아이디이거나, 잘못된 비밀번호입니다",
          )
        ) {
          setSingularError({
            code: 1,
            message: res.payload.message,
          });
        } else if (
          res.payload.message.includes(
            "해당 계정은 비활성화된 상태로 로그인할 수 없습니다.",
          )
        ) {
          setSingularError({
            code: 2,
            message: res.payload.message,
          });
          singularErrorModal(
            "해당 계정은 비활성화된 상태로 로그인할 수 없습니다.",
            "와이즈버즈 담당자에게 문의해 주시기 바랍니다.",
          );
        }
      }
    } catch (e: any) {
      const res = e.response;
      setError({
        result: true,
        message: res?.data?.message || JSON.stringify(res?.data || res),
      });
    } finally {
      setError({
        result: false,
        message: "",
      });
    }
  };
  const activeLogin = () => {
    const elem: any = document.getElementsByClassName("googleLoginBtn")[0];
    elem.click();
  };
  const clientId =
    "785404889528-cn4ln2gmg0es8499lkqknjng07vijppp.apps.googleusercontent.com";
  const errorLogin = (response: any) => {
    console.log(response);
  };

  const success = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    if ("tokenObj" in response) {
      try {
        const res: any = await dispatch(
          googleAsync({ token: `Bearer ${response.tokenObj.access_token}` }),
        );
        if (res.payload.result) {
          navigate("/benchmark");
        } else {
          if (
            res.payload.message.includes(
              "존재하지 않는 아이디이거나, 잘못된 비밀번호입니다",
            )
          ) {
            setSingularError({
              code: 1,
              message: res.payload.message,
            });
          } else if (
            res.payload.message.includes(
              "해당 계정은 비활성화된 상태로 로그인할 수 없습니다.",
            )
          ) {
            setSingularError({
              code: 2,
              message: res.payload.message,
            });
            singularErrorModal(
              "해당 계정은 비활성화된 상태로 로그인할 수 없습니다.",
              "와이즈버즈 담당자에게 문의해 주시기 바랍니다.",
            );
          }
        }
      } catch (e: any) {
        const res = e.response;
        setError({
          result: true,
          message: res?.data?.message || JSON.stringify(res?.data || res),
        });
      } finally {
        setError({
          result: false,
          message: "",
        });
      }
    }
  };
  useEffect(() => {
    if (error.result) {
      message.error(error.message).then();
    }
  }, [error.message, error.result]);

  useEffect(() => {
    // 로그인 페이지와 일반페이지 구분을 위한 클래스 추가.
    const _body = document.querySelector("body");
    _body?.classList.add("isLoginPage");
    return () => {
      _body?.classList.remove("isLoginPage");
    };
  }, []);

  return (
    <>
      <LoginWrapper>
        <form className={"form"} onSubmit={handleSubmit(onSubmit)}>
          <h2 className={"title"}>
            <img className={"logo"} src={Logo} alt={"Wisebirds"} />
            <span className={"text"}>BIRDS STUDIO</span>
          </h2>
          <div className={"input"}>
            <Controller
              name={"id"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "아이디를 입력해 주세요.",
                },
                pattern: {
                  value: EMAIL_REGEX,
                  message: "아이디는 올바른 이메일 주소로 입력해 주세요.",
                },
              }}
              render={({ field }) => {
                return (
                  <Input
                    className={clsx({
                      errorInput: _.get(errors, `id`),
                    })}
                    placeholder={"아이디(이메일)"}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      initSingularError();
                    }}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={`id`}
              render={({ message }) => <ErrorText>{message}</ErrorText>}
            />
          </div>
          <div className={"input"}>
            <Controller
              name={"pw"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "비밀번호를 입력해 주세요.",
                },
              }}
              render={({ field }) => {
                return (
                  <Input.Password
                    className={clsx({
                      errorInput: _.get(errors, `pw`),
                    })}
                    placeholder={"비밀번호"}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      initSingularError();
                    }}
                  />
                );
              }}
            />
            <ErrorMessage
              errors={errors}
              name={`pw`}
              render={({ message }) => <ErrorText>{message}</ErrorText>}
            />
            {singularError.code === 1 && (
              <ErrorText>{singularError.message}</ErrorText>
            )}
          </div>
          <Controller
            name={"keepLogin"}
            defaultValue={false}
            control={control}
            render={({ field }) => {
              return (
                <Checkbox
                  className={"input"}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                >
                  로그인 상태 유지
                </Checkbox>
              );
            }}
          />
          <Button type={"primary"} htmlType={"submit"} block>
            로그인
          </Button>
          <hr className={"line"} />
          <h4 className={"subTitle"}>와이즈버즈 로그인</h4>
          <Button
            type={"primary"}
            onClick={activeLogin}
            className={"googleLogin"}
            block
          >
            로그인
          </Button>
          <GoogleLogin
            className={"googleLoginBtn"}
            clientId={clientId}
            onSuccess={success}
            onFailure={errorLogin}
          />
          <div>
            <h4 className={"subTitleCopyRight"}>ⓒ WISEBIRDS. ALL right reserved</h4>
            <a className={"subTitlePrivacy"} href="https://adwitt.com/privacy.html" target="_blank">개인정보보호정책</a>
          </div>
        </form>
      </LoginWrapper>
    </>
  );
};

export default LandingPage;
