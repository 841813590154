import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiLogin, googleLogin } from "@app/api/auth.api";
import { Role } from "@app/interface/common.interface";
import { LoginParams, AuthState } from "@app/interface/auth.interface";
import { RootState } from "@app/stores/index";
import { tokenDecode } from "@app/utils/functions";
import _ from "lodash";

const name = "auth";

// Init
const initialState: AuthState = {
  role: "" as Role,
  name: "",
  id: 0,
  email: "",
  picture: "",
  company: "",
};

const loginGtag = (user: AuthState) => {
  window.gtag("event", "login", {
    // dimension1 = {{ user_id }}, dimension2 = wisebirds | adveriser, dimension3 = {{회사명}}
    dimention1: user.id,
    dimention2: user.company === "와이즈버즈" ? "wisebirds" : "adveriser",
    dimention3: user.company,
    metric1: "1",
  });
};

// AsyncThunk
export const loginAsync = createAsyncThunk(
  `${name}/loginAsync`,
  async (payload: LoginParams, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiLogin(payload);
      const { data } = response;
      if (response.status === 200) {
        if (data.result) {
          const { token } = data;
          const tokenResult = tokenDecode(token);
          dispatch(setToken(token));
          dispatch(setUser(_.omit(tokenResult, ["exp", "iss"])));
          loginGtag(tokenResult);
          return data;
        } else {
          return rejectWithValue(data);
        }
      } else {
        return rejectWithValue(data);
      }
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

export const googleAsync = createAsyncThunk(
  `${name}/googleAsync`,
  async (payload: object, { dispatch, rejectWithValue }) => {
    try {
      const response = await googleLogin(payload);
      const { data } = response;
      if (response.status === 200) {
        if (data.result) {
          const { token } = data;
          const tokenResult = tokenDecode(token);
          dispatch(setToken(token));
          dispatch(setUser(_.omit(tokenResult, ["exp", "iss"])));
          loginGtag(tokenResult);
          return data;
        } else {
          return rejectWithValue(data);
        }
      } else {
        return rejectWithValue(data);
      }
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  },
);

// Slice
const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    setToken: (state, action) => {
      const token = action.payload;
      localStorage.setItem("t", token);
    },
    setUser: (state, action) => {
      Object.assign(state, action.payload);
    },

    setInit: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {},
});

export const authSelector = (state: RootState) => state.auth;
export const { setInit, setUser, setToken } = authSlice.actions;
export default authSlice.reducer;
