import { axiosInstance } from "@app/api/request";
import { authHeader, queryEncode } from "@app/utils/functions";
import {
  DashboardTableGraphDataParameters,
  ShareDashboardReqParameter,
} from "@app/interface/dashboard.interface";
import { ListParameters } from "@app/interface/common.interface";

/*
 * title: 대시보드 목록
 * doc:https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2574319619
 * auth: PARTNER Only
 * desc: 광고주일때 사이드 네비 구축용.
 * */
export const getDashboard = () => {
  return axiosInstance.get(`/v1.0/dashboards`, authHeader());
};

/*
 * title: 대시보드 상세 정보
 * doc: https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2581823545
 * desc: 대시보드 상세정보 데이터 호출
 * */
export const getDashboardDetail = (params?: {
  dashboardId: string | number;
}) => {
  const query = queryEncode(params);
  return axiosInstance.get(`/v1.0/dashboards/info/?${query}`, authHeader());
};

/*
 * title: 대시보드 테이블 데이터
 * doc: https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2590310413
 * */
export const getDashboardTableData = (
  params: DashboardTableGraphDataParameters,
) => {
  const query = queryEncode(params);
  return axiosInstance.get(`/v1.0/dashboards/data/?${query}`, authHeader());
};

/*
 * title: 대시보드 그래프 데이터
 * doc: https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2590310424
 * */

export const getDashboardGraphData = (
  params: DashboardTableGraphDataParameters,
) => {
  const query = queryEncode(params);
  return axiosInstance.get(`/v1.0/dashboards/graph/?${query}`, authHeader());
};

/*
 * title: 전체 매트릭 리스트 조회
 * doc: https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2598207489
 *
 * */

export const getAllMetricList = () => {
  return axiosInstance.get(`/v1.0/dashboard-metrics`, authHeader());
};

/*
 * title: 대시보드 공유유저 리스트
 * doc: https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2581823489
 * */

export const getDashboardShareUserList = (params?: {
  dashboardId: string | number;
}) => {
  const query = queryEncode(params);
  return axiosInstance.get(`/v1.0/dashboard-partners/?${query}`, authHeader());
};

/*
 * title: 공유된 대시보드 리스트
 * doc: https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2581823507
 * */

export const getSharedDashboardList = (params: ListParameters) => {
  const query = queryEncode(params);
  return axiosInstance.get(
    `/v1.0/dashboards/shared-list/?${query}`,
    authHeader(),
  );
};

/*
 * title: 대시보드 삭제
 * doc: https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2593325057
 * */

export const deleteDashboard = (reqData: { dashboardId: string | number }) => {
  return axiosInstance.delete(`/v1.0/dashboards`, {
    ...authHeader(),
    data: reqData,
  });
};

/*
 * title: 대시보드 공유
 * doc: https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2582740995
 * */

export const newShareDashboard = (reqData: ShareDashboardReqParameter) => {
  return axiosInstance.post(`/v1.0/dashboards`, reqData, authHeader());
};

/*
 * title: 대시보드 저장(덮어쓰기)
 * doc: https://wisebirds.atlassian.net/wiki/spaces/CREP/pages/2603221049
 * */

export const overwriteShareDashboard = (
  reqData: Omit<ShareDashboardReqParameter, "name">,
) => {
  return axiosInstance.put(`/v1.0/dashboards/info`, reqData, authHeader());
};

export const editShareDashboard = (
  dashboardId: string | number,
  reqData: {
    dashboardName: string;
    shareUser: any[];
  },
) => {
  return axiosInstance.put(
    `/v1.0/dashboards/${dashboardId}`,
    reqData,
    authHeader(),
  );
};
