import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@app/stores/index";

const name = "etc";

// Init
const initialState: {
  path: string
} = {
  path: ''

};

// Slice
const etcSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPath: (state, action) => {
      state.path = action.payload
    },
  },
});

export const etcSelector = (state: RootState) => state.etc;
export const { setPath } = etcSlice.actions;
export default etcSlice.reducer;
