import React, { Suspense, useEffect } from "react";
import GNB from "@app/components/common/GNB";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import WbHeader from "@app/components/common/Header";
import SuspendFallbackLoading from "@app/layouts/suspendFallbackLoading";
import styled from "styled-components";

const headerHeight = "55px";
const StyledLayout = styled.div`
  display: grid;
  grid-template-areas: "header header" "nav-sidebar main-content";
  grid-template-columns: 200px auto;

  #header {
    grid-area: header;
    display: flex;
    flex-shrink: 0;
    position: fixed;
    width: 100%;
    padding: 0 30px;
    background-color: #fff;
    height: ${headerHeight};
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 25%);
    z-index: 10;
  }

  #aside {
    grid-area: nav-sidebar;
    position: sticky;
    margin: ${headerHeight} 0 0;
    padding: 15px 0 30px;
    // padding: 15px 30px 30px;
    height: calc(100vh - ${headerHeight});
    top: ${headerHeight};
    scroll-behavior: smooth;
    border-right: 3px solid #f4f4f4;
  }

  #content {
    grid-area: main-content;
    display: flex;
    overflow-x: hidden;
    padding-top: ${headerHeight};
    position: relative;
    flex-grow: 1;

    .content__inner {
      padding: 15px 30px 30px;
    }
  }

  .column {
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

const DashboardLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [navigate, location]);

  useEffect(() => {
    // 로그인 페이지와 일반페이지 구분을 위한 클래스 추가.
    const _body = document.querySelector('body');
    _body?.classList.add('isContentPage');
    return () => {
      _body?.classList.remove('isContentPage');
    }
  },[])

  return (
    <StyledLayout>
      <div id="header" className="header">
        <WbHeader />
      </div>
      <div id="aside" className="column">
        <GNB />
      </div>
      <div id="content" className="column">
        <div className={"content__inner"}>
          <Suspense
            fallback={
              <SuspendFallbackLoading
                message="message"
                description="description"
              />
            }
          >
            <Outlet />
          </Suspense>
        </div>
      </div>
    </StyledLayout>
    /* TODO: 테스트 후 삭제 예정 */
    /*<Layout className={"layout-page"}>
      <WbHeader />
      <Layout>
        <Layout.Sider
          style={{
            flex: "0 0 200px",
            maxWidth: "200px",
            minWidth: "200px",
            width: "200px",
            backgroundColor: "#fff",
            marginBottom: "6px",
            borderRight: "1px solid #f0f0f0",
            overflowY: "auto",
          }}
        >
          <GNB />
        </Layout.Sider>
        <Layout.Content
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
          }}
        >
          <div
            className={"content-layout"}
            style={{
              flex: "auto",
              overflow: "auto",
              padding: "6px",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "24px",
                borderRadius: "2px",
                width: "100%",
                display: "block",
              }}
            >
              <Suspense
                fallback={
                  <SuspendFallbackLoading
                    message="message"
                    description="description"
                  />
                }
              >
                <Outlet />
              </Suspense>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    </Layout>*/
  );
};
export default DashboardLayout;
