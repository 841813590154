import styled from "styled-components";

export const RequireText = styled.span`
  vertical-align: middle;
  &::before {
    display: inline-block;
    width: 5px;
    height: 5px;
    vertical-align: middle;
    background-color: #db4437;
    border-radius: 50%;
    content: "";
    line-height: 1.5715;
    margin-right: 5px;
  }
`;

export const ErrorText = styled.span`
  color: #db4437;
  white-space: nowrap;
`;
