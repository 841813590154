import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth.store";
import manageReducer from "./manange.store";
import etcReducer from './etc.store'

const rootReducer = combineReducers({
  auth: authReducer,
  manage: manageReducer,
  etc: etcReducer
});

export default rootReducer;
