import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { authSelector } from "@app/stores/auth.store";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Menu } from "antd";
import {
  GnbMasterList,
  GnbAdminPartnerList,
  GnbListProps,
} from "@app/utils/variables";
import { getDashboard } from "@app/api/dashboard.api";
import { useNavigate } from "react-router-dom";

const generateMenus = (data: any) => {
  return data.map((item: any) => {
    if (item.children) {
      return (
        <Menu.SubMenu key={item.link} title={item.text}>
          {generateMenus(item.children)}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item key={item.link}>
        <RouterLink to={item.link}>{item.text}</RouterLink>
      </Menu.Item>
    );
  });
};

function GNB() {
  const location = useLocation();
  const { role } = useSelector(authSelector);
  const [initLoading, setInitLoading] = useState(true);
  const [navList, setNavList] = useState<any>([]);
  const navigate = useNavigate();

  const pathname = useMemo(() => {
    const path = location.pathname;
    const regex = /(\/setting|\/new|\/edit)/g;
    if (path.search(regex) !== -1) {
      return path.substr(0, path.search(regex));
    } else {
      if (path.includes("dashboard")) {
        return "/dashboard";
      } else if (path.includes("benchmark")) {
        return "/benchmark";
      } else {
        return path;
      }
    }
  }, [location.pathname]);

  const fetchDashboard = useCallback(async () => {
    setInitLoading(true);
    await getDashboard()
      .then(async (res) => {
        const { data } = res;
        const converterList = await data.map((ele: any) => {
          return {
            text: ele.name,
            link: `/dashboard/${ele.id}`,
          };
        });
        const PartnerGNBList: GnbListProps[] = [
          {
            text: "통합 대시보드",
            link: "/dashboard",
            children: [...converterList],
          },
        ];
        setNavList(PartnerGNBList);
        setInitLoading(false);
        if (converterList.length === 0) {
          navigate("/dashboard");
        } else {
          navigate(converterList[0].link);
        }
      })
      .catch((e) => {
        console.log(e, "GNB Error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (role === "PARTNER") {
      fetchDashboard();
    }
  }, [fetchDashboard, role]);

  if (role === "MASTER") {
    return (
      <Menu
        mode={"inline"}
        defaultOpenKeys={["/manage"]}
        selectedKeys={[pathname]}
        style={{ borderRight: 0, width: "calc(100% - 1px)" }}
      >
        {generateMenus(GnbMasterList)}
      </Menu>
    );
  }
  if (role === "ADMIN" || role === "MEMBER") {
    /* 측정 항목 나오지 않음 */
    return (
      <Menu
        mode={"inline"}
        defaultOpenKeys={["/manage"]}
        selectedKeys={[pathname]}
        style={{ borderRight: 0, width: "calc(100% - 1px)" }}
      >
        {generateMenus(GnbAdminPartnerList)}
      </Menu>
    );
  }
  return (
    <>
      {initLoading ? (
        <>Loading...</>
      ) : (
        <Menu
          mode={"inline"}
          defaultOpenKeys={["/dashboard"]}
          selectedKeys={[location.pathname]}
          style={{ borderRight: 0, width: "calc(100% - 1px)" }}
        >
          {generateMenus(navList)}
        </Menu>
      )}
    </>
  );
}

export default GNB;
