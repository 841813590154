import { axiosInstance } from "@app/api/request";
import { LoginParams } from "@app/interface/auth.interface";
import { authHeader } from "@app/utils/functions";
import { EditMyAccountRequestParameters } from "@app/interface/auth.interface";

/* 로그인 */
export const apiLogin = (payload: LoginParams) => {
  return axiosInstance.post("/v1.0/login", payload);
};

/* 구글 로그인 */
export const googleLogin = (payload: Object) => {
  return axiosInstance.post("/v1.0/login", payload);
};

/* 광고주 자신 정보 수정 */
export const editMyAccount = (reqData: EditMyAccountRequestParameters) => {
  return axiosInstance.put(`/v1.0/me`, reqData, authHeader());
};

// export const editMyName = (name: object) => {
//   return axiosInstance.put(`/v1.0/me`, name, authHeader());
// };
