import React from "react";
import RenderRouter from "./routes";
import TagManager from "react-gtm-module";
import { init as initApm } from "@elastic/apm-rum";
import { BrowserRouter } from "react-router-dom";

const tagManagerArgs = {
  gtmId: "GTM-TW25PPC",
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <>
      <BrowserRouter>
        <RenderRouter />
      </BrowserRouter>
    </>
  );
}

export default App;

initApm({
  serviceName: "birds-studio-frontend",
  serverUrl: "https://deapm.wisebirds.ai",
  environment: process.env.REACT_APP_HOST_ENV,
  serviceVersion: "0.0.1",
});
