import { FC, useEffect } from "react";
import { Route, Navigate } from "react-router-dom";
import _ from "lodash";
import { RouteProps } from "react-router";
import { tokenDecode } from "@app/utils/functions";
import { useDispatch } from "react-redux";
import { setToken, setUser } from "@app/stores/auth.store";

const PrivateRoute: FC<RouteProps> = (props) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("t");
  const currentUnixDate = Math.floor(new Date().getTime() / 1000);
  const expiresMatch = token
    ? _.get(tokenDecode(token), "exp") < currentUnixDate
    : true;

  // 임시저장
  useEffect(() => {
    if (token && !expiresMatch) {
      const tokenResult = tokenDecode(token);
      dispatch(setToken(token));
      dispatch(setUser(_.omit(tokenResult, ["exp", "iss"])));
    }
  }, [dispatch, token, expiresMatch]);

  return !token || expiresMatch ? (
    <Navigate to={"/login"} />
  ) : (
    <Route {...props} />
  );
};

export default PrivateRoute;
