import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import rootReducer from "@app/stores/rootReducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
