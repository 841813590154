import React from "react";
import { Spin, Alert } from "antd";

interface FallbackMessageProps {
  message: string;
  description?: string;
}

const SuspendFallbackLoading: React.FC<FallbackMessageProps> = ({
  message,
  description,
}) => {
  return (
    <Spin tip="Loading...">
      <Alert message={message} description={description} type="info" />
    </Spin>
  );
};

export default SuspendFallbackLoading;
