import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  authSelector,
  setInit,
  setToken,
  setUser,
} from "@app/stores/auth.store";
import { Button, Col, Dropdown, Input, Menu, message, Modal, Row } from "antd";
import type { LayoutProps } from "antd";
import styled from "styled-components";
import Logo from "@app/assets/images/cl_logo.svg";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { InputCount } from "@app/components/common/Input";
import { editMyAccount } from "@app/api/auth.api";
import clsx from "clsx";
import _ from "lodash";
import { ErrorText } from "@app/components/common/Text";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorProps } from "@app/interface/common.interface";
import { EditMyAccountRequestParameters } from "@app/interface/auth.interface";
import { tokenDecode } from "@app/utils/functions";
import { CaretDownOutlined } from "@ant-design/icons";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  flex: auto;

  .header__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;

    .text {
      font-size: 18px;
      margin-left: 10px;
    }
  }

  .header__info {
    display: flex;
    align-items: center;
    justify-content: stretch;

    .info {
      text-align: right;

      .company {
        font-size: 12px;
        color: #8d8d8e;
      }
    }

    .menu {
      margin-left: 10px;
    }
  }
`;

const labelWidth = "150px";
const WbHeader = (props: LayoutProps) => {
  const { role, name, company, email } = useSelector(authSelector);
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm<EditMyAccountRequestParameters>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState<ErrorProps>({
    result: false,
    message: "",
  });

  const DropdownMenu = (
    <Menu>
      {role === "PARTNER" && (
        <Menu.Item key={"0"}>
          <Button type={"text"} onClick={showModal}>
            내 정보 변경
          </Button>
        </Menu.Item>
      )}
      <Menu.Item key={"1"}>
        <Button type={"text"} onClick={onLogout}>
          로그아웃
        </Button>
      </Menu.Item>
    </Menu>
  );
  function showModal() {
    setIsModalVisible(true);
  }
  async function onLogout() {
    await dispatch(setInit());
    await localStorage.removeItem("t");
    navigate("/login");
  }
  function handleCancel() {
    setIsModalVisible(false);
  }
  function onSubmit(data: any) {
    // 빈값은 삭제.
    const reqData: any = _.omitBy(data, _.isEmpty);

    editMyAccount(reqData)
      .then((res) => {
        const { data } = res;
        const tokenResult = tokenDecode(data.token);
        dispatch(setToken(data.token));
        dispatch(setUser(_.omit(tokenResult, ["exp", "iss"])));
        message.success("변경 완료되었습니다.").then();
        setIsModalVisible(false);
      })
      .catch((e) => {
        const res = e.response;
        setError({
          result: true,
          message: res.data.message || JSON.stringify(res.data),
        });
      })
      .then(() => {
        setError({
          result: false,
          message: "",
        });
      });
  }
  useEffect(() => {
    if (error.result) {
      message.error(error.message).then();
    }
  }, [error.message, error.result]);

  return (
    <>
      <StyledHeader {...props}>
        <div className="header__logo">
          <img src={Logo} alt={"Wisebirds"} />
          <span className={"text"}>BIRDS STUDIO</span>
        </div>
        <div className={"header__info"}>
          <div className={"info"}>
            <div className={"company"}>{company}</div>
            <div className={"name"}>
              <span>
                {name}({email})
              </span>
            </div>
          </div>
          <div className={"menu"}>
            <Dropdown
              overlay={DropdownMenu}
              placement={"bottomRight"}
              trigger={["click"]}
            >
              <Button icon={<CaretDownOutlined />} type={"text"} />
            </Dropdown>
          </div>
        </div>
      </StyledHeader>
      <Modal
        title="내 정보 변경"
        visible={isModalVisible}
        onOk={handleSubmit(onSubmit)}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button key="submit" type="primary" onClick={handleSubmit(onSubmit)}>
            저장
          </Button>,
        ]}
      >
        <Row>
          <Col flex={labelWidth}>이름</Col>
          <Col flex={"auto"}>
            <div>
              <Controller
                name={"name"}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "필수 정보입니다.",
                  },
                }}
                defaultValue={name}
                render={({ field }) => {
                  return (
                    <InputCount
                      name="name"
                      maxLength={30}
                      className={clsx({
                        errorInput: _.get(errors, `name`),
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={`name`}
                render={({ message }) => <ErrorText>{message}</ErrorText>}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col flex={labelWidth}>아이디(이메일)</Col>
          <Col flex={"auto"}>
            <div>
              <Input disabled={true} value={email} />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col flex={labelWidth}>새 비밀번호</Col>
          <Col flex={"auto"} style={{ maxWidth: "320px" }}>
            <div>
              <Controller
                name={"pw"}
                control={control}
                rules={{
                  validate: (value: any) => {
                    if (!_.isEmpty(value)) {
                      let matchCount = 0;
                      if (
                        /^[\w-~․!@#$%^&*()+=[]|;:'"<>,.?]{8,16}$/.test(value)
                      ) {
                        if (/[a-zA-Z]/.test(value)) matchCount++;
                        if (/[-~․!@#$%^&*()_+=[]|;:'"<>,.?]/.test(value))
                          matchCount++;
                        if (/[0-9]/.test(value)) matchCount++;
                      }
                      if (
                        matchCount < 2 ||
                        value.length < 8 ||
                        value.length > 16
                      ) {
                        return "8~16자 영문, 숫자, 특수문자 2가지 이상 조합으로  입력해 주세요.";
                      }
                    }
                  },
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <Input.Password
                      name="pw"
                      placeholder={"8~16자 영문 대,소문자, 숫자, 특수문자"}
                      className={clsx({
                        errorInput: _.get(errors, `pw`),
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={`pw`}
                render={({ message }) => <ErrorText>{message}</ErrorText>}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col flex={labelWidth}>새 비밀번호 확인</Col>
          <Col flex={"auto"} style={{ maxWidth: "320px" }}>
            <div>
              <Controller
                name={"repeatPw"}
                control={control}
                rules={{
                  validate: (value: any) => {
                    if (!_.isEmpty(value)) {
                      const pw = getValues(`pw`);
                      if (pw !== undefined || pw !== "") {
                        return value === pw || "비밀번호가 일치하지 않습니다.";
                      }
                    }
                  },
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <Input.Password
                      name="repeatPw"
                      placeholder={"8~16자 영문 대,소문자, 숫자, 특수문자"}
                      className={clsx({
                        errorInput: _.get(errors, `repeatPw`),
                      })}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                    />
                  );
                }}
              />
              <ErrorMessage
                errors={errors}
                name={`repeatPw`}
                render={({ message }) => <ErrorText>{message}</ErrorText>}
              />
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default WbHeader;
