import React, { useCallback, useEffect, useState } from "react";
import { Input, InputProps } from "antd";
import styled from "styled-components";

interface InputCountProps extends InputProps {
  wrapperStyle?: React.CSSProperties;
}

const InputCountWrapper = styled.div`
  position: relative;
  &::after {
    font-size: 12px;
    position: absolute;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
    content: attr(data-count);
    pointer-events: none;
    right: 6px;
    top: 10px;
  }
`;

export const InputCount = ({
  maxLength,
  wrapperStyle,
  onChange,
  ...props
}: InputCountProps) => {
  const [currentLength, setCurrentLength] = useState(0);
  const handleChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange],
  );
  useEffect(() => {
    setCurrentLength(
      props.value && typeof props.value === "string" ? props.value.length : 0,
    );
  }, [props.value]);

  return (
    <InputCountWrapper
      data-count={`(${currentLength}/${maxLength})`}
      style={wrapperStyle}
    >
      <Input
        {...props}
        onChange={handleChange}
        maxLength={maxLength}
        style={{
          paddingRight: maxLength
            ? maxLength < 100
              ? "45px"
              : "60px"
            : undefined,
        }}
      />
    </InputCountWrapper>
  );
};
