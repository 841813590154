import { Media } from "@app/interface/common.interface";

export interface GnbListProps {
  text: string;
  link: string;
  children?: any;
}

export const GnbMasterList: GnbListProps[] = [
  {
    text: "벤치마크",
    link: "/benchmark",
  },
  {
    text: "통합 대시보드",
    link: "/dashboard",
  },
  {
    text: "관리",
    link: "/manage",
    children: [
      {
        text: "광고계정 관리",
        link: "/manage/ad-account",
      },
      {
        text: "광고계정 그룹 관리",
        link: "/manage/ad-account-group",
      },
      {
        text: "측정항목 관리",
        link: "/manage/metrics",
      },
      {
        text: "광고주 관리",
        link: "/manage/advertiser",
      },
    ],
  },
  {
    text: "데이터 설정",
    link: "/data-setting",
    children: [
      {
        text: "데이터 수집",
        link: "/data-setting/collection",
      },
    ],
  },
];

export const GnbAdminPartnerList: GnbListProps[] = [
  {
    text: "벤치마크",
    link: "/benchmark",
  },
  {
    text: "통합 대시보드",
    link: "/dashboard",
  },
  {
    text: "관리",
    link: "/manage",
    children: [
      {
        text: "광고계정 관리",
        link: "/manage/ad-account",
      },
      {
        text: "광고계정 그룹 관리",
        link: "/manage/ad-account-group",
      },
      {
        text: "광고주 관리",
        link: "/manage/advertiser",
      },
    ],
  },
];
export const MEDIA: {
  kakao: string;
  facebook: string;
  google: string;
  instagram: string;
  twitter: string;
  [key: string]: any;
} = {
  kakao: "카카오",
  facebook: "페이스북",
  google: "구글",
  instagram: "인스타그램",
  twitter: "트위터",
};
export const STATUS: {
  ALL: string;
  ACTIVE: string;
  INACTIVE: string;
  [key: string]: any;
} = {
  ALL: "전체",
  ACTIVE: "활성",
  INACTIVE: "비활성",
};
export const CURRENCY: {
  KRW: string;
  USD: string;
  [key: string]: any;
} = {
  KRW: "한국 원(KRW)",
  USD: "미국 달러(USD)",
};
export const mediaList: Array<{ label: string; value: Media }> = [
  {
    label: "구글",
    value: "GOOGLE",
  },
  {
    label: "네이버",
    value: "NAVER",
  },
  {
    label: "인스타그램",
    value: "INSTAGRAM",
  },
  {
    label: "트위터",
    value: "TWITTER",
  },
  {
    label: "페이스북",
    value: "FB",
  },
  {
    label: "카카오",
    value: "KAKAO",
  },
];
export const MEDIA_COLORS: {
  FB: string;
  INSTAGRAM: string;
  GOOGLE: string;
  KAKAO: string;
  TWITTER: string;
  NAVER: string;
  [key: string]: string;
} = {
  FB: "#03166b",
  INSTAGRAM: "#ea7484",
  KAKAO: "#ffc000",
  GOOGLE: "#06bab3",
  TWITTER: "#50a2ff",
  NAVER: "#AAAAAA",
};

export const EMPTY_REGEX = /^\s+$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
