import { TokenResult } from "@app/interface/auth.interface";
import jwtDecode from "jwt-decode";
import moment from "moment";
import queryString from "query-string";
import { OptionsProps } from "@app/interface/common.interface";
import { Select } from "antd";


// 토큰 변환
export const tokenDecode = (token: string): TokenResult => {
  return jwtDecode<TokenResult>(token);
};

// 인증
export const authHeader = () => {
  return { headers: { Authorization: `Bearer ${localStorage.getItem("t")}` } };
};

// 테이블
export const tableSorter = (sorter: any) => {
  function sortOrder(order: string) {
    return order === "ascend" ? "asc" : "desc";
  }
  if (Array.isArray(sorter)) {
    return sorter.map((value: any) => {
      return `${value.field},${sortOrder(value.order)}`;
    });
  } else {
    if (!sorter.field || !sorter.order) {
      return "";
    } else {
      return `${sorter.field},${sortOrder(sorter.order)}`;
    }
  }
};
export function generateSortOrder(sort, target) {
  const sortSplit = sort.split(",");
  if (sortSplit[0]) {
    if (sortSplit[0] === target) {
      return sortSplit[1] === "asc" ? "ascend" : "descend";
    }
  }
  return undefined;
}

export const formatDate = (date: string, format = "YYYY-MM-DD HH:mm:ss") => {
  return date ? moment(new Date(date).getTime()).format(format) : "-";
};

export const queryEncode = (
  query: any,
  options = { skipEmptyString: true, skipNull: true },
) => {
  return queryString.stringify(query, options);
};

export const optionGenerator = (data: Array<OptionsProps>) => {
  return data.map((node, idx) => {
    return (
      <Select.Option key={idx} value={node.value}>
        {node.label}
      </Select.Option>
    );
  });
};

export const numberWithCommas = (x: any) => {
  if (x === null || x === undefined) return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const currencyFixed = (unit, value) => {
  if (unit === "USD") {
    return value.toFixed(2);
  }
  return value;
};
