// TODO: 500에러일때 (토큰 만료일시 로그인 페이지로 자동이동)

import axios from "axios";

export const axiosInstance = axios.create({
  timeout: 6000,
  baseURL: process.env.REACT_APP_API_ADDRESS, //TODO profile 설정
  headers: {
    "Content-Type": "application/json",
  },
});
