import React, { lazy, useEffect, useCallback } from "react";
import { PartialRouteObject } from "react-router";
import { useRoutes, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { etcSelector, setPath } from "@app/stores/etc.store";
import { initManageParams } from "@app/stores/manange.store";

import DashboardLayout from "@app/layouts/DashboardLayout";
import Landing from "@app/pages/Landing";
import PrivateRoute from "./privateRoute";

// const Landing = lazy(() => import("@app/pages/Landing"));
const NotFound = lazy(() => import("@app/pages/NotFoundView"));
const Dashboard = lazy(() => import("@app/pages/Dashboard"));
const DashboardDetails = lazy(() => import("@app/pages/Dashboard/Details"));
const Benchmark = lazy(() => import("@app/pages/Benchmark"));
const AdAccount = lazy(() => import("@app/pages/Manage/AdAccount"));
const AdAccountSetting = lazy(
  () => import("@app/pages/Manage/AdAccount/Setting"),
);
const AdAccountGroup = lazy(() => import("@app/pages/Manage/AdAccountGroup"));
const AdAccountGroupNew = lazy(
  () => import("@app/pages/Manage/AdAccountGroup/New"),
);
const AdAccountGroupEdit = lazy(
  () => import("@app/pages/Manage/AdAccountGroup/Edit"),
);
const Metrics = lazy(() => import("@app/pages/Manage/Metrics"));
const MetricsNew = lazy(() => import("@app/pages/Manage/Metrics/New"));
const MetricsEdit = lazy(() => import("@app/pages/Manage/Metrics/Edit"));
const Advertiser = lazy(() => import("@app/pages/Manage/Advertiser"));
const AdvertiserNew = lazy(() => import("@app/pages/Manage/Advertiser/New"));
const AdvertiserEdit = lazy(() => import("@app/pages/Manage/Advertiser/Edit"));
const Collection = lazy(() => import("@app/pages/DataSetting/Collection"));

const routerList: PartialRouteObject[] = [
  {
    path: "login",
    element: <Route element={<Landing />} />,
  },
  {
    path: "/",
    element: <PrivateRoute element={<DashboardLayout />} />,
    children: [
      {
        path: "benchmark",
        element: <Route element={<Benchmark />} />,
      },
      {
        path: "benchmark/:id",
        element: <Route element={<Benchmark />} />,
      },
      {
        path: "dashboard",
        element: <Route element={<Dashboard />} />,
      },
      {
        path: "dashboard/:id",
        element: <Route element={<DashboardDetails />} />,
      },
      {
        path: "manage/ad-account",
        element: <Route element={<AdAccount />} />,
      },
      {
        path: "manage/ad-account/setting/:id",
        element: <Route element={<AdAccountSetting />} />,
      },
      {
        path: "manage/ad-account-group",
        element: <Route element={<AdAccountGroup />} />,
      },
      {
        path: "manage/ad-account-group/new",
        element: <Route element={<AdAccountGroupNew />} />,
      },
      {
        path: "manage/ad-account-group/edit/:id",
        element: <Route element={<AdAccountGroupEdit />} />,
      },
      {
        path: "manage/metrics",
        element: <Route element={<Metrics />} />,
      },
      {
        path: "manage/metrics/new",
        element: <Route element={<MetricsNew />} />,
      },
      {
        path: "manage/metrics/edit/:id",
        element: <Route element={<MetricsEdit />} />,
      },
      {
        path: "manage/advertiser",
        element: <Route element={<Advertiser />} />,
      },
      {
        path: "manage/advertiser/new",
        element: <Route element={<AdvertiserNew />} />,
      },
      {
        path: "manage/advertiser/edit/:company",
        element: <Route element={<AdvertiserEdit />} />,
      },
      {
        path: "data-setting/collection",
        element: <Route element={<Collection />} />,
      },
    ],
  },
  {
    path: "*",
    element: <Route element={<NotFound />} />,
  },
];

const RenderRouter: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { path: prevPath } = useSelector(etcSelector);

  const checkRouter = useCallback(() => {
    const currentPath = location.pathname;
    const currentPathArray = currentPath.split("/").filter((value) => value);
    const prevPathArray = prevPath.split("/").filter((value) => value);
    const isManage = currentPathArray.includes("manage");
    if (!isManage) {
      // Manage가 아닐경우
      dispatch(initManageParams());
    } else {
      if (prevPathArray[1] !== currentPathArray[1]) {
        dispatch(initManageParams());
      }
    }
    dispatch(setPath(currentPath));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);

  useEffect(() => {
    checkRouter();
  }, [checkRouter]);
  return useRoutes(routerList);
};

export default RenderRouter;
